import { Delete, Edit, Star, StarBorder } from "@suid/icons-material";
import { useTheme, Typography } from "@suid/material";
import { ParentComponent, createMemo, createSignal, splitProps } from "solid-js";
import { type TileProps } from ".";
import { StyledButtonArea, StyledIconArea, StyledTile, StyledTileAvatar, StyledTileName } from "./Tile.styled";
import { stringAvatar } from "../../utilities/Parser/GetInitials";

export const Tile: ParentComponent<TileProps> = (props) => {
    const [pinState, setPinState] = createSignal(false);
    const [loaded, setLoaded] = createSignal(!props.image);
    const [failed, setFailed] = createSignal(false);
    const [local, other] = splitProps(props, [
        "active",
        "editMode",
        "disabled",
        "placeholder",
        "error",
        "id",
        "image",
        "insertPosition",
        "loading",
        "name",
        "nameShown",
        "onDelete",
        "onEdit",
        "onTogglePin",
        "gridColumn",
    ]);
    const theme = useTheme();
    const topRadius = props.circular ? "50%" : props.radius ?? "16px";
    const bottomRadius = props.nameShown ? theme.spacing(1) : topRadius;
    const avatar = createMemo(() => stringAvatar(local.name, !loaded() || !local.image));

    return (
        <StyledTile
            data-testid={`Tile-${local.id}`}
            ownerState={local}
            onClick={(event) => {
                // Don't take action of there was a sub-action called.
                if (event.defaultPrevented) return;
                other.onClick?.(local.id);
            }}
            sx={{ borderRadius: `${topRadius} ${topRadius} ${bottomRadius} ${bottomRadius}` }}
        >
            {!local.image || typeof local.image === "string" ? <StyledTileAvatar
                src={local.image}
                {...local}
                children={avatar()?.children}
                sx={{ borderRadius: topRadius, opacity: loaded() || failed() ? 1 : 0, transition: "all 0.3s", ...avatar()?.sx }}
                imgProps={{ onLoad: () => setLoaded(true), onError: () => setFailed(true) }}
                variant={props.circular ? "circular" : "rounded"}
            /> : local.image}
            <StyledIconArea>
                {local.editMode && local.onEdit && !local.disabled && (
                    <StyledButtonArea onClick={(event) => {
                        local.onEdit?.(local.id);
                        event.preventDefault();
                    }}>
                        <Edit/>
                    </StyledButtonArea>
                )}
                {local.editMode && local.onDelete && !local.disabled && (
                    <StyledButtonArea onClick={(event) => {
                        local.onDelete?.(local.id);
                        event.preventDefault();
                    }}>
                        <Delete />
                    </StyledButtonArea>
                )}
                {local.editMode && local.onTogglePin && !local.disabled && (
                    <StyledButtonArea onClick={(event) => {
                        setPinState((pin) => !pin);
                        local.onTogglePin?.(local.id, pinState());
                        event.preventDefault();
                    }}>
                        {pinState() ? <Star/> : <StarBorder/>}
                    </StyledButtonArea>
                )}
            </StyledIconArea>
            <StyledTileName ownerState={local}>
                <Typography variant="caption" as="div">{local.name}</Typography>
            </StyledTileName>
        </StyledTile>
    );
};
