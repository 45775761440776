import { Avatar, IconButton, alpha } from "@suid/material";
import { IconButtonTypeMap } from "@suid/material/IconButton";
import styled from "@suid/system/styled";
import { type StyledProps } from "@suid/system/styledProps";
import { type TileProps } from ".";

export const StyledTile = styled("button", {
    name: "Tile",
})<TileProps>(
    ({ theme, ownerState }) => {
        const styles: StyledProps = {
            all: "unset",
            display: "block",
            width: "100%",
            position: "relative",
            textDecoration: "none",
            boxShadow: "none",
            transition: "all 0.3s ease-in-out",
            paddingBottom: ownerState.nameShown === false ? "100%" : `calc(100% + 40px + ${theme.spacing(0.5)})`,
            ...(ownerState.placeholder &&
                {
                    outline: `3px solid ${ownerState.error ? theme.palette.error.main : "transparent"}`,
                }),

            ...(!ownerState.disabled &&
                {
                    "&:hover, &:focus": {
                        "& .MuiAvatar-root": {
                            boxShadow: theme.shadows[2],
                            transform: "translate( -50%, 0% ) scale(1.025)",
                        },
                        cursor: "pointer",
                        zIndex: 1,
                    },
                }),
            ...(ownerState.clone && {
                animation: "pop 150ms cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                cursor: "grabbing",
            }),
            ...((ownerState.active || ownerState.disabled) && {
                opacity: 0.5,
            }),
            ...(ownerState.gridColumn !== undefined && {
                gridColumn: ownerState.gridColumn,
            }),
            ...(!ownerState.active &&
            !ownerState.clone &&
            ownerState.insertPosition === "before" && {
                transform: `translateX(${theme.spacing(2)})`,
                "&::after": {
                    content: '""',
                    top: theme.spacing(0),
                    left: theme.spacing(-2.5),
                    position: "absolute",
                    width: theme.spacing(0.25),
                    height: "100%",
                },
            }),
            ...(!ownerState.active &&
            !ownerState.clone &&
            ownerState.insertPosition === "after" && {
                transform: `translateX(-${theme.spacing(2)})`,
                "&::after": {
                    content: '""',
                    top: theme.spacing(0),
                    right: theme.spacing(-2.5),
                    position: "absolute",
                    width: theme.spacing(0.25),
                    height: "100%",
                },
            }),
            ...(!ownerState.active &&
            !ownerState.clone &&
            ownerState.insertPosition === "over" && {
                transform: "scale(1.2)",
            }),
            ...(!ownerState.active && !ownerState.clone && ownerState.insertPosition === "over" && {}),
        };

        return styles;
    },
);

export const StyledIconArea = styled("div")<{ position: "left" | "right" }>(
    ({ theme, ownerState }) => ({
        "&:empty": {
            display: "none",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: theme.spacing(0),
        padding: theme.spacing(0),
        color: theme.palette.primary.main,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        ...(ownerState?.position !== "left" && { right: theme.spacing(0) }),
        ...(ownerState?.position === "left" && { left: theme.spacing(0) }),
        [theme.breakpoints.down("sm")]: {
            top: "1px",
            ...(ownerState?.position !== "left" && { right: "1px" }),
            ...(ownerState?.position === "left" && { left: "1px" }),
        },
    }),
);

export const StyledButtonArea = styled(IconButton)<IconButtonTypeMap & { actionType?: "edit" | "delete"; softDelete?: boolean }>(
    ({ theme, ownerState }) => ({
        ...(ownerState?.actionType === "edit" && {
            color: theme.palette.primary.main,

            "&:hover": {
                backgroundColor: "white",
                color: theme.palette.primary.dark,
            },
        }),

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0),
        },

        ...(ownerState?.actionType === "delete" && {
            color: ownerState.softDelete
                ? theme.palette.primary.main
                : theme.palette.error.main,

            "&:hover": {
                backgroundColor: "white",
                color: ownerState?.softDelete
                    ? theme.palette.primary.main
                    : theme.palette.error.dark,
            },
        }),
    }),
);

/**
 * Styled Avatar for usage in a Tile.
 * Note that using ownerState will break the color mechanism.
 */
export const StyledTileAvatar = styled(Avatar)<TileProps>(
    ({ theme, props, ownerState }): StyledProps => ({
        position: "absolute",
        left: "50%",
        transform: "translate( -50%, 0% )",
        display: "flex",
        color: theme.palette.common.white,
        width: (props as typeof ownerState).nameShown === false ? "100%" : `calc( 100% - ${theme.spacing(2)} )`,
        height: (props as typeof ownerState).nameShown === false ? "100%" : `calc((100% - 40px - ${theme.spacing(2.5)}))`,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (props as typeof ownerState).placeholder ? "#EAECF0" : theme.palette.common.white,
        "& img": {
            display: "block",
            maxWidth: "100%",
            maxHeight: "100%",
            // TODO: make sure SUID allows multiple values for the same key (currently doesn't work).
            // imageRendering: ["crisp-edges", "-webkit-optimize-contrast", "-moz-crisp-edges"],
            imageRendering: "crisp-edges",
            objectFit: "initial", /* Cover is not supported on old browsers */
        },
    }),
);

export const StyledTileName = styled("div", {
    name: "Tile",
    slot: "name",
})<TileProps>(
    ({ theme, ownerState }) => ({
        position: "absolute",
        bottom: theme.spacing(1.5),
        display: ownerState.nameShown === false ? "none" : "flex",
        lineHeight: "18px",
        fontSize: "14px",
        minHeight: "40px",
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        width: "100%",
        textAlign: "center",
        justifyContent: "center",

        "& div": {
            width: "100%",
            margin: 0,
            overflow: "hidden",
            // Maximum of 2 lines
            overflowWrap: "break-word",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
            backgroundColor: alpha(theme.palette.background.paper as string, 0.6),
        },
    }),
);
